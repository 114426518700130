import { stringToBoolean } from '@framework/utils/parse-util'
import { toNumber } from '@framework/utils/lodash'
import { GENERAL_ADDRESS, GENERAL_LANDMARK } from './textVariables'

//BETTERCOMMERCE ENDPOINTS
export const HOMEPAGE_SLUG = `/`
export const OMS_SHIPPING_PLANS = '/api/v1/oms/shipment/plans'
export const OMS_CLICK_AND_COLLECT = '/api/v1/oms/store/clickandcollect'
export const NEXT_CLICK_AND_COLLECT = '/api/shipping-plans/click-collect'
export const NEXT_SHIPPING_PLANS = '/api/shipping-plans/plans'
export const NEXT_UPDATE_DELIVERY_INFO = '/api/shipping-plans/update-delivery'
export const CATEGORY_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/catalog/category`
export const XML_FEED = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/content/feed`
export const NAV_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/content/nav`
export const COLLECTIONS_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/catalog/collection`
export const LOOKBOOK_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/catalog/lookbook`
export const CACHED_IMAGE_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/catalog/product`
export const LOOKBOOK_SLUG_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/catalog/lookbook/slug`
export const SITEVIEW_ENDPOINT = `api/${process.env.NEXT_PUBLIC_API_VERSION}/content/siteview`
export const CATALOG_ENDPOINT = `api/${process.env.NEXT_PUBLIC_API_VERSION}/catalog/brand`
export const SEARCH_MINIMAL_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/catalog/search/advanced/minimal`
export const SEARCH_ADVANCED_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/catalog/search/advanced`
export const NEXT_SEARCH_ADVANCED_ENDPOINT =
  '/api/catalog/get-category-products'
export const PRODUCT_API_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/catalog/product/`
export const PRODUCT_PREVIEW_API_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/catalog/product/preview`
export const BASKET_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/basket`
export const GET_BASKET_PROMOTIONS_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/promotion/basket-promotions`
export const REGISTER_CUSTOMER = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/customer/create`
export const AUTHENTICATE_CUSTOMER = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/customer/authenticate`
export const CUSTOMER_BASE_API = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/customer/`
export const CUSTOMER_RETURN_RMA_API = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/return/rma`
export const CANCEL_REASON = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/content/masterdata`
export const CUSTOMER_NEWSLETTER = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/customer/newsletter/subscribe`
export const CUSTOMER_GET_OR_SAVE_UPIS_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/customer`
export const ORDERS_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/order/`
export const CUSTOMER_ORDERS_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/order/customer/`
export const RETURN_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/return/`
export const ADDRESS_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/address/`
export const CREATE_ADDRESS_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/address/create`
export const NEXT_STORE_LOCATOR = '/api/store-locator/get-stores'
export const CATALOG_SEARCH = `api/${process.env.NEXT_PUBLIC_API_VERSION}/catalog/search/r`
export const STATIC_PRODUCT_PATHS = `api/${process.env.NEXT_PUBLIC_API_VERSION}/infra/staticpath/product`
export const STORE_LOCATOR_API = '/api/v1/oms/store'
export const BASE_SEARCH_ENDPOINT = `api/${process.env.NEXT_PUBLIC_API_VERSION}/catalog/search`
export const BASE_SEARCH_MINIMAL_ENDPOINT = `api/${process.env.NEXT_PUBLIC_API_VERSION}/catalog/search/advanced/minimal`
export const SHIPPING_ENDPOINT = `api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/shipping-method`
export const CHECKOUT_ENDPOINT = `api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/checkout`
export const PAYMENTS_ENDPOINT = `api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/checkout/payment-methods`
export const RETURNS_ENDPOINT = `api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/return`
export const INFRA_ENDPOINT = `api/${process.env.NEXT_PUBLIC_API_VERSION}/infra/config`
export const KEYWORDS_ENDPOINT = `api/${process.env.NEXT_PUBLIC_API_VERSION}/catalog/search/keyword-redirections`
export const LOQATE_ADDRESS = '/api/loqate'
export const RETRIEVE_ADDRESS = '/api/retrieve-address'
export const NOTIFICATION_OTP_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/notification/otp`
export const NOTIFICATION_GOKWIK_OTP_ENDPOINT = `/${process.env.NEXT_PUBLIC_GOKWIK_API_VERSION}/services/auth/otp/send`
export const GOKWIK_GET_ADDRESS_ENDPOINT = `/${process.env.NEXT_PUBLIC_GOKWIK_API_VERSION}/address/get`
export const GOKWIK_ADDRESS_MANAGE = `/${process.env.NEXT_PUBLIC_GOKWIK_API_VERSION}/address/manage`
export const VALIDATE_CHANGE_USERNAME_OTP_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/notification`
export const BASKET_VALIDATE_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/basket/validate`
export const INFRA_LOG_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/infra/log`
export const VOUCHER_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/promotion`

// REFERRAL ENDPOINTS
export const REFERRAL_PROGRAM = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/referral/program`
export const REFERRER_BYUSERID = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/referral/referrer/by-userid`
export const REFERRER_BYUSERNAME = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/referral/referrer/by-username`
export const REFERRER_BYEMAIL = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/referral/referrer/by-email`
export const REFERRAL_CAPTURE_INVITES = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/referral/referee`
export const REFERRAL_VOUCHERS = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/referral/referrer/vouchers`
export const REFERRAL_VALIDATE_CODE = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/referral/referrer/validate-code`
export const REFERRAL_REGISTER = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/referral/referee`

// NPS ENDPOINT
export const CREATE_NPS = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/nps/create`
// PickPayGo
export const GET_PHYSICAL_STORES = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/stores/`
export const GET_PRODUCT_DETAIL_BARCODE = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/catalog/product/barcode?barcode=`
export const GET_STORE_ORDER_DATA = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/stores/by-id/`

// Store
export const GET_ALL_STORES = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/stores/all`
export const GET_STORES_DETAILS = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/stores`
export const POST_STORE_BY_POSTALCODE = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/stores`

//LOCAL ENDPOINTS
export const PAGE_CONTENT_ENDPOINT = `api/${process.env.BETTERCMS_API_VERSION}/content-entry` //`api/${process.env.BETTERCMS_API_VERSION}/page`;
export const PAGE_PREVIEW_CONTENT_ENDPOINT = `api/${process.env.BETTERCMS_API_VERSION}/page`
export const BLOG_LIST_ENDPOINT = `/api/${process.env.BETTERCMS_API_VERSION}/page/list`
export const NEXT_API_PRICE_MATCH_ENDPOINT = `/api/price-match`
// export const NEXT_API_KEYWORDS_ENDPOINT = '/api/keywords'
export const NEXT_API_NOTIFY_ME_ENDPOINT = `/api/notify-me`
export const PRICE_MATCH_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/basket/pricematch/add`
export const NEXT_ADD_TO_CART = `/api/add-item-cart`
export const NEXT_BULK_ADD_TO_CART = `/api/bulk-add-cart`
export const NEXT_UPDATE_CART_INFO = `/api/update-cart-info`
export const NEXT_GET_ORDER_DETAILS = '/api/order/order-details'
export const NEXT_GET_CUSTOMER_ORDER_DETAILS = '/api/customer/order-details'
export const NEXT_GET_CUSTOMER_ORDER_DETAILS_RMA =
  '/api/customer/order-details-rma'
export const NEXT_GET_ORDER_RELATED_PRODUCTS = '/api/customer/related-products'
export const NEXT_GET_ALT_RELATED_PRODUCTS =
  '/api/catalog/get-alternate-related-product'
export const NEXT_GET_CUSTOMER = `/api/customer/get-customer`
export const NEXT_GET_CART = `/api/get-cart`
export const NEXT_GET_BASKET_PROMOS = `/api/get-basket-promotions`
export const NEXT_ASSOCIATE_CART = '/api/customer/associate-cart'
export const NEXT_MERGE_CART = '/api/merge-cart'
export const NEXT_GET_USER_CART = '/api/get-user-cart'
export const NEXT_SIGN_UP = `/api/signup`
export const NEXT_VALIDATE_EMAIL = `/api/customer/validate-email`
export const NEXT_AUTHENTICATE = `/api/login`
export const NEXT_UPDATE_DETAILS = `/api/customer/update-details`
export const NEXT_SUBSCRIBE = `/api/customer/subscribe`
export const NEXT_GET_ORDERS = `/api/customer/orders`
export const NEXT_SAVE_CUSTOM_INFO = `/api/customer/save-custom-info`
export const NEXT_GET_WISHLIST = `/api/customer/get-wishlist`
export const NEXT_CREATE_WISHLIST = `/api/customer/create-wishlist`
export const NEXT_REMOVE_WISHLIST = `/api/customer/remove-item-from-wishlist`
export const NEXT_GET_SINGLE_LOOKBOOK = '/api/get-single-lookbook'
export const NEXT_ADDRESS = `/api/customer/address`
export const NEXT_EDIT_ADDRESS = `/api/customer/edit-address`
export const NEXT_CREATE_ADDRESS = `/api/customer/create-address`
export const NEXT_DELETE_ADDRESS = `/api/customer/delete-address`
export const NEXT_GET_JUSPAY_SAVED_UPIS = `/api/customer/upi/juspay/get-upis`
export const NEXT_SAVE_JUSPAY_UPI = `/api/customer/upi/juspay/save-upi`
export const NEXT_DELETE_JUSPAY_UPI = `/api/customer/upi/juspay/delete-upi`
export const NEXT_GET_PAGE_CONTENT = `/api/content/get-page-content`
export const NEXT_GET_PAGE_PREVIEW_CONTENT = `/api/content/get-page-preview-content`
export const JUSPAY_API = `/api/payments/juspay`

export const NEXT_CREATE_REVIEW = `/api/create-review`
export const NEXT_GET_NAVIGATION = `/api/get-navigation`

export const NEXT_APPLY_PROMOTION = `/api/apply-promo`
export const NEXT_DELIVERY_INFO = '/api/checkout/get-estimated-delivery'
export const NEXT_PINCODE_LOOKUP = '/api/checkout/pincode-lookup'
export const NEXT_SHIPPING_ENDPOINT = '/api/shipping-options'
export const NEXT_UPDATE_CHECKOUT_ADDRESS = '/api/update-checkout-address'
export const NEXT_UPDATE_SHIPPING = '/api/update-shipping'
export const NEXT_GUEST_CHECKOUT = '/api/customer/guest-checkout'
export const NEXT_LOGIN_CHECKOUT = '/api/customer/login-checkout'
export const NEXT_SEARCH_PRODUCTS = `/api/catalog/search`
export const NEXT_SEARCH_MINIMAL_PRODUCTS = `/api/catalog/minimal-search`
export const NEXT_GET_PRODUCT = '/api/catalog/get-product'
export const NEXT_GET_PRODUCT_QUICK_VIEW_MODAL =
  '/api/catalog/get-product-quick-view-modal'
export const NEXT_GET_PREVIEW = '/api/catalog/get-preview'
export const NEXT_GET_PRODUCT_PREVIEW = '/api/catalog/get-product-preview'
export const NEXT_GET_PRODUCT_QUICK_VIEW = '/api/catalog/get-product-quick-view'
export const NEXT_PAYMENT_METHODS = '/api/payment-methods'
export const NEXT_CONFIRM_ORDER = '/api/confirm-order'
export const NEXT_DELIVERABILITY_ETA = '/api/order-deliverability-eta'
export const NEXT_INFRA_ENDPOINT = '/api/infra'
export const NEXT_SET_CONFIG = '/api/set-config'
export const NEXT_POST_PAYMENT_RESPONSE = '/api/payment-response'
export const NEXT_POST_PAYMENT_RESPONSE_NEW = '/api/payment-response-new'
export const NEXT_UPDATE_BASKET_CUSTOM_INFO = '/api/checkout/update-custom-info'
export const NEXT_FORGOT_PASSWORD = '/api/customer/forgot-password'
export const NEXT_RESET_PASSWORD = '/api/customer/reset-password'
export const NEXT_VALIDATE_TOKEN = '/api/auth/validate-token'
export const NEXT_GET_RETURN_DATA = '/api/return/get-return-data'
export const NEXT_CREATE_RETURN_DATA = '/api/return/create'
export const NEXT_GET_RETURNS = '/api/return/get-user-returns'
export const JUSTPAY_CHECKOUT = '/api/payments/justpay-checkout'
export const NEXT_OTP_REQUEST = '/api/notification/otp'
export const NEXT_GOKWIK_OTP_REQUEST = '/api/notification/goKwikOtp'
export const NEXT_GOKWIK_ADDRESS_REQUEST = '/api/customer/goKwikAddress'
export const NEXT_GOKWIK_ADDRESS_MANAGE = '/api/customer/goKwikManageAddress'
export const NEXT_REVIEW_CONFIG_API_ENDPOINT = '/api/v1/review-configurations'
export const NEXT_GET_PDP_LOOKBOOK = '/api/catalog/get-pdp-lookbook'
export const NEXT_GET_PDP_CACHED_IMAGES = '/api/catalog/get-pdp-cached_image'
export const NEXT_GET_PRODUCT_REVIEW = '/api/catalog/get-product-review'
export const NEXT_GET_RELATED_PRODUCTS = '/api/catalog/get-related-products'
export const NEXT_GET_PRODUCT_PROMOS = '/api/catalog/get-product-promos'
export const NEXT_GET_PDP_LOOKBOOK_PRODUCT =
  '/api/catalog/get-pdp-lookbook-product'
export const NEXT_REVIEW_CONFIG_API = '/api/catalog/get-review-setting'
export const NEXT_CANCEL_REASON = `/api/cancel-reason`
export const NEXT_RETURN_REASON = `/api/return-reason`
export const NEXT_CANCEL_ORDER = `/api/cancel-order`
export const NEXT_CANCEL_ORDER_LINE = `/api/cancel-order-line`
export const NEXT_REVIEW_DETAIL = `/api/catalog/get-product-review`
export const NEXT_BLOG_DETAIL = `/api/catalog/get-blog-details`
export const NEXT_RETURN_ORDER_LINE = `/api/return-order-line`
export const NEXT_BASKET_VALIDATE = `/api/basket/validate`
export const NEXT_VALIDATE_CHANGE_USERNAME_OTP = `/api/notification/validate-change-username-otp`
export const NEXT_GET_USER_VOUCHER = `/api/voucher/get-user-voucher`
export const NEXT_CHECK_FOR_FREE_GIFT =
  '/api/CheckForFreeGift/check-for-free-gift'

// elastic-search-api
export const ELASTIC_SEARCH_API = `/query_suggestion`
export const ELASTIC_PRODUCT_LIST = `/search.json`
export const NEXT_ELASTIC_SEARCH_API = `/api/elasticSearch/post-elastic-search-keyword`
export const NEXT_ELASTIC_PRODUCT_LIST = `/api/elasticSearch/post-elastic-product-list`

// facebook conversion api
export const NEXT_POST_CONVERISON_API = `https://graph.facebook.com`
export const NEXT_CONVERISON_API = `/api/facebookConversion/post-converstion`

// Referral
export const NEXT_REFERRAL_PROGRAM = `/api/referral/referral-program`
export const NEXT_REFRRER_BYUSERID = `/api/referral/referral-byuserid`
export const NEXT_REFRRER_BYUSERNAME = `/api/referral/referral-byusername`
export const NEXT_REFRRER_BYEMAIL = `/api/referral/referral-byemail`
export const NEXT_REFERRAL_CAPTURE_INVITES = `/api/referral/referral-capture-invite`
export const NEXT_REFERRAL_VOUCHERS = `/api/referral/referral-vouchers`
export const NEXT_REFERRAL_VALIDATE_CODE = `/api/referral/referral-validate-code`
export const NEXT_REFERRAL_REGISTER = `/api/referral/referral-register`

// NPS
export const NEXT_CREATE_NPS = `/api/create-nps`
// PickPayGo
export const NEXT_GET_PHYSICAL_STORES = `/api/pickpaygo/get-physical-stores`
export const NEXT_GET_PRODUCT_DETAILS_BARCODE =
  '/api/pickpaygo/get-product-data'
export const NEXT_GET_PRODUCT_PREVIEW_DETAILS_BARCODE =
  '/api/pickpaygo/get-product-data-preview'
export const NEXT_GET_STORE_ORDER_DATA = '/api/pickpaygo/shop-order-details'

export const PROMOTION_API_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/promotion/`
export const PRODUCT_PROMOTION_API_ENDPOINT = `/api/${process.env.NEXT_PUBLIC_API_VERSION}/commerce/promotion/product-promotions`

// Store
export const NEXT_GET_ALL_STORES = `/api/stores/get-all-stores`
export const NEXT_GET_STORES_DETAILS = `/api/stores/get-store-details`
export const NEXT_POST_STORE_BY_POSTALCODE =
  '/api/stores/get-store-by-postalcode'
export const NEXT_GET_GOOGLE_API = `https://maps.googleapis.com/maps/api/place/autocomplete/json`
export const NEXT_GET_PLACE_DETAILS = `https://maps.googleapis.com/maps/api/place/details/json`
export const NEXT_GOOGLE_AUTOCOMPLETE_API = `/api/stores/get-place-by-google-api`
export const NEXT_PLACE_DETAILs_API = `/api/stores/get-addess-details-api`
export const NEXT_GET_SIZE_CHART = '/api/get-size-chart'

export const NEW_RELIC_LOG_API = !!process.env.NEXT_NEW_RELIC_LOG_API_ENDPOINT
  ? `${process.env.NEXT_NEW_RELIC_LOG_API_ENDPOINT}/log/v1`
  : undefined
export const NEW_RELIC_LOG_API_KEY = process.env.NEXT_NEW_RELIC_LICENSE_KEY
export const NEW_RELIC_APP_NAME = process.env.NEXT_NEW_RELIC_APP_NAME
export const NEW_RELIC_GUID_ID = process.env.NEXT_NEW_RELIC_GUID_ID

//CONSTANTS
export const DefaultSessionCookieKey: string = `defaultSession`
export const SessionIdCookieKey: string = `sessionId`
export const UtmCookieKey: string = `utm`
export const UtmAppsFlyerCookieKey: string = `utmAppsflyer`
export const DeviceIdKey: string = `deviceId`
export const valueForLoginLogout: string = `askForLoginLogout`

//Error Message for token
export const UserTokenMessage: string = 'User Token is Invalid'

//SHIPPING ACTION TYPES
export const SHIPPING_ACTION_TYPES_MAP = {
  GET_ALL: 'GET_ALL',
  CLICK_AND_COLLECT: 'CLICK_AND_COLLECT',
  ACTIVE_SHIPPING_METHODS: 'ACTIVE_SHIPPING_METHODS',
}

export const STRIPE_CHECKOUT_SESSION = '/api/payments/stripe-checkout-session'

export const NEXT_GEO_ENDPOINT =
  process.env.NEXT_PUBLIC_GEO_ENDPOINT ||
  'https://omnilytics.bettercommerce.io/api/v1/IpInfo?ipAddress='
export const UPDATE_ORDER_STATUS = '/api/update-order-status'

export const NEXT_PUBLIC_DEFAULT_CACHE_TIME =
  process.env.NEXT_PUBLIC_DEFAULT_CACHE_TIME_IN_MILLI_SECS
export const NEXT_PUBLIC_API_CACHING_LOG_ENABLED =
  process.env.NEXT_PUBLIC_API_CACHING_LOG_ENABLED

export const NEXT_PUBLIC_IMAGE_BASE_URL = process.env.NEXT_PUBLIC_IMAGE_BASE_URL

// Default currency, language & country settings.
export const BETTERCOMMERCE_DEFAULT_CURRENCY =
  process.env.BETTERCOMMERCE_DEFAULT_CURRENCY
export const BETTERCOMMERCE_DEFAULT_LANGUAGE =
  process.env.BETTERCOMMERCE_DEFAULT_LANGUAGE
export const BETTERCOMMERCE_DEFAULT_COUNTRY =
  process.env.BETTERCOMMERCE_DEFAULT_COUNTRY
export const BETTERCOMMERCE_DEFAULT_COUNTRY_CODE =
  process.env.BETTERCOMMERCE_DEFAULT_COUNTRY_CODE

// Override currency, language & country settings ONLY FOR specific storefronts WHEREVER REQUIRED.
export const BETTERCOMMERCE_CURRENCY = process.env.BETTERCOMMERCE_CURRENCY
export const BETTERCOMMERCE_LANGUAGE = process.env.BETTERCOMMERCE_LANGUAGE
export const BETTERCOMMERCE_COUNTRY = process.env.BETTERCOMMERCE_COUNTRY
export const NEXT_ORDER_RETURN_EXCHANGE_THRESHOLD_UTC_DATE: string =
  '2023-05-03T18:00:00.000'
export const BETTERCOMMERCE_OMS_ENABLED: boolean = stringToBoolean(
  process.env.BETTERCOMMERCE_OMS_ENABLED
)
export const SHOW_APPLY_COUPON_SECTION = stringToBoolean(
  process.env.SHOW_APPLY_COUPON_SECTION ?? 'false'
)
export const PDP_REVIEW_ACCEPTABLE_IMAGE_MIMES =
  process.env.PDP_REVIEW_ACCEPTABLE_IMAGE_MIMES
export const PDP_REVIEW_NO_OF_IMAGES_ALLOWED = toNumber(
  process.env.PDP_REVIEW_NO_OF_IMAGES_ALLOWED
)
export const PDP_REVIEW_IMAGE_SIZE_IN_BYTES = toNumber(
  process.env.PDP_REVIEW_IMAGE_SIZE_IN_BYTES
)

export const isMicrosoftClarityEnable = stringToBoolean(
  process.env.NEXT_PUBLIC_MICROSOFT_CLARITY_ENABLE
)
export const microsoftClarityProjectId =
  process.env.NEXT_PUBLIC_MICROSOFT_CLARITY_PROJECT_ID

export const NEXT_PUBLIC_CUSTOMER_REVIEW_IMAGES_DISABLED = stringToBoolean(
  process.env.NEXT_PUBLIC_CUSTOMER_REVIEW_IMAGES_DISABLED ?? 'false'
)

export const IMAGE_SIZE_IN_BYTES = toNumber(process.env.IMAGE_SIZE_IN_BYTES)
export const DESKTOP_BANNER_IMAGE_HEIGHT = toNumber(
  process.env.NEXT_PUBLIC_DESKTOP_BANNER_IMAGE_HEIGHT
)
export const MOBILE_BANNER_IMAGE_HEIGHT = toNumber(
  process.env.NEXT_PUBLIC_MOBILE_BANNER_IMAGE_HEIGHT
)

export const HOME_PAGE_DEFAULT_SLUG = 'home'
export const APP_VERSION = 'app-version'
export const TERMS_PAGE_DEFAULT_SLUG = 'terms-and-conditions'
export const ABOUT_US_PAGE_DEFAULT_SLUG = 'about-us-new'
export const FAQ_PAGE_DEFAULT_SLUG = 'dafaq'
export const POLICY_PAGE_DEFAULT_SLUG = 'privacy-policy'
export const BLOG_PAGE_ID = '96aa3d32-e3f7-469b-8080-e2da5d164c03'
export const BLOG_COLS = 'blogheader.blogheader_mainimage'
export const STOCK_CODE = 'DAM-ES-SHT-AHG-M'
export const TOP_PINCODE = '560103'
export const freeStorePickUp = 'f928e9834'
// Referral
export const REFERRAL_PAGE_DEFAULT_SLUG = 'referral'
export const THANK_YOU_DEFAULT_SLUG = 'uoy-knaht'

export enum LoginPageAction {
  SEND_OTP = 0,
  LOGIN = 1,
  GUEST_LOGIN = 2,
}
export enum AddressPageAction {
  SAVE = 0,
  SELECT_ADDRESS = 1,
}
export enum ProfilePageAction {
  SAVE = 0,
  SEND_OTP_FOR_CHANGE_MOBILE_NO = 1,
  CHANGE_MOBILE_NO = 2,
}
export enum PaymentPageAction {
  VERIFY_AND_UPI_PAY = 0,
  CARD_PAYMENT = 1,
  SAVED_UPI_PAY = 2,
  NETBANKING_PAYMENT = 3,
  NETBANKING_PAYMENT_OTHER = 4,
  PAY_VIA_WALLET = 5,
  PAY_VIA_CASH = 6,
}
export enum PaymentFailedPageAction {
  TRY_WITH_SAME_PAYMENT_METHOD = 400,
  USE_DIFFERENT_METHOD = 401,
  PAY_COD = 402,
}
export enum CartAction {
  UPDATE_SIZE = 0,
}

export enum DeliveryInfoPageAction {
  COMPUTE_EDD = 0,
}

export enum API_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export enum CURRENT_HOME_COMPONENT_PAGE {
  HOME = 'HOME',
  OTHERS = 'OTHERS',
}

export const EmptyGuid = '00000000-0000-0000-0000-000000000000'
export const ProdutId = '802a6d12-e4c1-4f64-997c-af4000d637da'
export const BasketProdutId = '4f45efdc-5028-49cf-bac0-af4000d63789'
export const collectionSlug = 'you-may-also-like'

export enum FromValues {
  CHECKOUT = 0,
}

export enum OrderStatus {
  PENDING = 'Pending',
  INCOMPLETE = 'Incomplete',
  APPROVED = 'Approved',
  RETURNED_FROM_WAREHOUSE = 'RetrunedFromWarehouse',
  UNFULFILLABLE = 'Unfulfillable',
  ACCEPTED_IN_WAREHOUSE = 'AcceptedInWarehouse',
  ACCEPTED = 'Accepted',
  READY_TO_DISPATCH = 'ReadyToDispatch',
  DISPATCH = 'Dispatch',
  PICKED_UP = 'PICKED UP',
  SHIPMENT_DELAYED = 'SHIPMENT DELAYED',
  IN_TRANSIT = 'In Transit',
  OUT_FOR_DELIVERY = 'OUT FOR DELIVERY',
  DELIVERED = 'Delivered',
  FAILED_DELIVERY = 'FAILED DELIVERY',
  RTO_REQUESTED = 'RTO REQUESTED',
  RTO_RECEIVED = 'RTO Received',
  RTO_RECEIVED_1 = 'RTOReceived',
  CANCELLED = 'Cancelled',
  CANCELLED_FAILED_FRAUD_SCREENING = 'CancelledFailedFraudScreening',
  CANCELLED_FAILED_FRAUD_SCREENING_1 = 'OrderStatusCancelledFailedFraudScreening',
  RETURN_REQUESTED = 'Return Requested',
  RMA_FAILED_SCREENING = 'RMA FailedScreening',
  RMA_PROCESSING = 'RMA Processing',
  CONFIRMED = 'Confirmed',
  RETURN_OUT_FOR_PICKUP = 'Return Out for Pickup',
  RETURN_PICKUP_RESCHEDULED = 'Return Pickup Rescheduled',
  RETURN_PICKEDUP = 'Return Pickedup',
  RETURN_PICKUP_CANCELLED = 'Return Pickup Cancelled',
  RMA_RECEIVED = 'RMA Received',
  REFUNDED = 'Refunded',
  CANCELLED_BY_CUSTOMER = 'CancelledByCustomer',
  CANCELLED_BY_STORE = 'CancelledByStore',
}

export enum OrderLogStatus {
  Unknown = 0,
  Incomplete = 1,
  PreOrderUntrusted = 10,
  Processing = 20,
  Complete = 30,
  Cancelled = 40,
  Pending = 2,
  Approved = 3,
  SentToWarehose = 4,
  AcceptedInWarehouse = 5,
  OrderSettled = 7,
  Dispatch = 9,
  ReadyToDispatch = 12,
  Accepted = 21,
  Create = 22,
  AwaitingCustomerFeedback = 23,
  Refunded = 24,
  ReturnedByWarehouse = 25,
  AwaitingStock = 26,
  IDREQUIRED = 107,
  AwaitingSettlement = 6,
  PreOrderApproved = 11,
  ProblemWithOrder = 100,
  DeclinedPayment = 101,
  CancelledByStore = 102,
  CancelledByStorePaymentIssue = 103,
  CancelledByStoreStockIssue = 104,
  CancelledByCustomer = 105,
  RequestReturn = 106,
  IdRequired = 107,
  RefundRequired = 108,
  RefundApproved = 109,
  CancelledFailedFraudScreening = 110,
  PartiallyDespatched = 15,
  WaitForStock = 27,
  Pickedup = 41,
  ShipmentDelayed = 42,
  OutForDelivery = 43,
  FailedDelivery = 44,
  RTORequested = 45,
  RTOReceived = 46,
  ReturnOutforPickup = 47,
  ReturnPickupRescheduled = 48,
  ReturnPickedup = 49,
  ReturnPickupCancelled = 50,
  RMAReceived = 51,
  ExchangeRequested = 52,
  ExchangeDenied = 53,
  InTransit = 54,
  FailedInOMS = 200,
  New = 201,
  CancelledByLapsedJob = 202,
  AwaitingTracking = 205,
  Void = 206,
  ProofOfBillingRequired = 207,
  PhotoIdAndProofOfBillingRequired = 208,
  RequestACall = 209,
  VerifyPayPalEmailAddress = 210,
  Delivered = 211,
}

export enum CheckoutType {
  NONE = 0,
  NORMAL = 1,
  EXPRESS = 2,
}

export enum ReviewInputPageAction {
  SUBMIT = 100,
}

export enum CancelOrderPageAction {
  CANCEL = 300,
}

export module QueryString {
  export const FROM = 'fr'
}

export module Messages {
  export module Validations {
    export module RegularExpressions {
      export const MOBILE_NUMBER =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
      export const EMAIL =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      export const FULL_NAME = /^[A-Z a-z]+$/
      // export const ADDRESS_LINE = /^[a-zA-Z 0-9\-\,\/\.]*$/;
      // export const ADDRESS_LINE = /^[a-zA-Z 0-9!@#$%^&*()_+={[}\]:;\"'<,>.?/|\\-]*$/;
      export const ADDRESS_LINE = /^[a-zA-Z 0-9&/.',#;:@_-]*$/
      export const ADDRESS_LABEL = /^[a-zA-Z 0-9\-\,\/\.]*$/
      export const CARD_NUMBER = /^[0-9]*$/
      export const CARD_EXPIRY = /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/
      export const CARD_CVV = /^[0-9]*$/
      export const URL =
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
      export const FIND_EMPTY_CHARACTERS = /\s/g
      export const REPLACE_DEFAULT_UPI_WEB_PREFIX_URL = /upi:\/\//g
    }

    export const Login: any = {
      MOBILE_NUMBER_REQUIRED: 'Mobile number is a required field',
      MOBILE_NUMBER_INPUT: 'Mobile number should only contain numbers',
      MOBILE_NUMBER_LENGTH: 'Mobile number should be 10 digits',
    }

    export const Profile: any = {
      NAME_REQUIRED: 'Full name is a required field',
      NAME_MIN_LENGTH: 'Full name must be at least 3 characters',
      NAME_INPUT: 'Full name should only contain alphabets',

      MOBILE_NUMBER_REQUIRED: 'Mobile number is a required field',
      MOBILE_NUMBER_INPUT: 'Mobile number should only contain numbers',
      CHANGED_MOBILE_NUMBER_INPUT:
        'New mobile number entered is currently in use',
      EMAIL_REQUIRED: 'Email is a required field',
      EMAIL_INPUT: 'Email is not valid',
    }

    export const AddNewAddress: any = {
      PIN_CODE_REQUIRED: 'Pincode is a required field',
      PIN_CODE_UNREACHABLE: 'Pincode non-serviceable',
      PIN_CODE_NUM: 'Pincode should contain only digits',
      PIN_CODE_MIN_LENGTH: 'Pincode must be at least 6 digits',
      CITY_REQUIRED: 'City is a required field',
      STATE_REQUIRED: 'State is a required field',

      ADDRESS_1_REQUIRED: GENERAL_ADDRESS + ' is a required field',
      ADDRESS_1_INPUT: GENERAL_ADDRESS + ' should only contain alpha-numerics',
      ADDRESS_2_INPUT: GENERAL_LANDMARK + ' should only contain alpha-numerics',

      NAME_REQUIRED: 'Name is a required field',
      NAME_MIN_LENGTH: 'Name must be at least 3 characters',
      NAME_INPUT: 'Name should only contain alphabets',

      MOBILE_NUMBER_REQUIRED: 'Mobile number is a required field',
      MOBILE_NUMBER_INPUT: 'Mobile number should only contain numbers',

      ADDRESS_TYPE_REQUIRED: 'Address is a required field',
      ADDRESS_TYPE_MIN_LENGTH:
        GENERAL_ADDRESS + ' should have minimum 15 characters',
      ADDRESS_TYPE_INPUT:
        'Address should only contain alpha-numerics and special characters',
      EMAIL_TYPE_REQUIRED: 'Email is a required field',
      EMAIL_INVALID: 'Enter a valid email address',
    }

    export const AddNewCard: any = {
      CARD_NUMBER_REQUIRED: 'Card number is a required field',
      CARD_NUMBER_MIN_LENGTH: 'Card Number must be at least 14 characters',
      CARD_NUMBER_INPUT: 'Card number should contain only digits',

      EXPIRY_REQUIRED: 'Expiry is a required field',
      EXPIRY_INPUT: 'Expiry should be in MM/YY format',

      CVV_REQUIRED: 'CVV is a required field',
      CVV_INPUT: 'CVV should only contain alpha-numerics',

      NAME_REQUIRED: 'Name is a required field',
      NAME_MIN_LENGTH: 'Name must be at least 3 characters',
      NAME_INPUT: 'Name should only contain alphabets',
    }

    export const SaveUPI: any = {
      VPA_REQUIRED: 'VPA / UPI ID is a required field',
    }

    export const DeliveryInfo: any = {
      PIN_CODE_REQUIRED: 'Pincode is a required field',
      PIN_CODE_MIN_LENGTH: 'Pincode must be at least 6 digits',
      VALID_PIN: 'Please enter a valid pincode',
      PIN_CODE_MAX_LENGTH: 'Pincode must be at max 6 characters',
      PIN_CODE_INPUT: 'Pincode should only contain numbers',
    }
  }

  export const Messages: any = {
    // RETURN_SUCCESS: 'Return success',
    // EXCHANGE_SUCCESS: 'Exchange successful',
    RETURN_SUCCESS: 'Order return initiated!',
    EXCHANGE_SUCCESS: 'Order Exchange Initiated!',
    RESET_CACHE_SUCCESS: 'Cache reset successfully!',
  }

  export const Warnings: any = {}

  export const Errors: any = {
    INVALID_REQUEST:
      'The information provided is incomplete. Please try again.',
    ERR_BAD_REQUEST:
      'The information provided is incomplete. Please try again.',
    CARD_NOT_SUPPORTED: 'Card type is not supported. Please try again.',
    INVALID_OTP_SUPPLIED: 'OTP is not valid. Please try again.',
    ERROR_UPDATE_ADDITIONAL_CHARGES:
      'Error applying COD additional charges. Please try again after sometime.',
    UNSUPPORTED_UPI_APP: 'UPI payment is unsupported.',
    NOT_FOUND: 'Some Error Encountered. Please refresh the page and try again',
    USERNAME_ALREADY_EXISTS: 'User already exists',
    CUSTOMER_NOT_FOUND: 'Customer not found.',
    GENERIC_ERROR:
      'Some Error Encountered. Please refresh the page and try again.',
    CART_EMPTY: 'Your cart is empty',
    CART_ITEM_QTY_LIMIT_EXCEEDED: 'Max allowed quantity is 5.',
    CART_ITEM_QTY_MAX_ADDED: 'You have added the maximum available quantity.',
    BASKET_VALIDATION_FAILED: 'Basket validation failed',
    PAYMENT_ALREADY_PROCCESSED: 'Payment for your basket is already processed.',
    BASKET_AND_ORDER_VALUE_MISMATCH:
      "Payment declined because actual order amount doesn't match total basket amount.",
    'YourBag.Links.EmptyBag': 'Payment for your basket is already completed.',
  }
}

// constants used for making keyboards autofocus on various screens
export const PAGE_TYPE_LOGIN_MODULE = 'LOGIN_MODULE'
export const PAGE_TYPE_ADDRESS = 'ADDRESS'
export const PAGE_TYPE_VPA = 'VPA'

// used for site-wide alert ribbons
export const ALERT_TIMER = 5000
export const SHORT_ALERT_TIMER = 2500
export const SHORT_CLOSE_TIMER = 200

//pickPayGo values
export const OneDayOrderLimit = 1
export const SevenDayOrderLimit = 1
export const MonthOrderLimit = 1
export const SixtyDaysOrderLimit = 2
export const NintyDaysOrderLimit = 3
export const exceptionNumber = [
  '9830630481',
  '9099035652',
  '8309409868',
  '9991701854',
  '9971122199',
  '8167639741',
  '9000000008',
  '8630299516',
  '9412380804',
  '8979868918',
  '9650225533',
  '9113937812',
  '9560991656',
  '9044423731',
  '7275073101',
  '9464974671',
  '7009327886',
  '9804851226',
  '9716872179',
]
export const blockedNumber = [
  '8617551591',
  '8128284437',
  '9931040139',
  '9823170637',
  '7889070875',
  '7015516496',
  '9053751560',
  '9915690241',
  '9216900845',
  '7015288034',
  '8128128138',
  '7666758838',
  '8053082572',
  '8954250001',
  '8264098499',
  '7615927667',
  '8806002372',
  '7404190002',
  '8219606857',
  '9810320943',
  '8437473757',
  '7419285992',
  '9416322853',
  '9896304744',
  '9671635501',
  '9034334983',
  '9034334982',
  '8168364881',
  '9711340444',
  '9831235354',
  '7405083112',
  '7218764456',
  '9317542000',
  '9809000089',
  '9888153316',
  '8235958105',
]

export const USER_TOKEN_ERROR = [
  'user token is invalid',
  'invalid user token',
  'invalied user token',
  'user token expired',
]

export const DATE_FORMAT = 'DD-MMM-yy'
export const DATE_TIME_FORMAT = 'DD-MMM-yy HH:mm'
export const DAY_DATE_FORMAT = 'dddd, DD MMM'
export const DAY_DATE_FORMAT_2 = 'ddd, DD MMM'
export const DAY_DATE_FORMAT_3 = 'dddd, DD MMM YY | hh:mm A'
export const DAY_DATE_FORMAT_4 = 'dddd, DD MMM YY'
export const DAY_DATE_FORMAT_5 = 'ddd, DD MMM YY'
export const DAY_DATE_FORMAT_6 = 'ddd, DD/MM/YY'
export const DAY_DATE_FORMAT_7 = 'YYYY-MM-DD'
export const DAY_DATE_FORMAT_8 = `DD MMM YY`
export const PRODUCTS_SLUG_PREFIX = 'p/'

export const MAX_QTY_TO_ADD_INTO_CART = 5
export const CONVERT_ORDER_VALIDATION_MESSAGES = [
  'YourBag.Links.EmptyBag',
  'PAYMENT_ALREADY_PROCCESSED',
]

// used for props in infinite scrolling
export const PAGE_TYPE_PLP = 'PLP'
export const PAGE_TYPE_PDP = 'PDP'

// APP LINKS
export const APP_LINK_HAMBURGER = 'https://damensch.app/ham-menu'
// export const GOOGLE_PLAY_URL = 'https://damensch.app/footer'
// export const APPLE_STORE_URL = 'https://www.apple.com/in/app-store/'

// PLP BANNERS URLS
export const PLP_BANNER_1 =
  'https://img.damensch.com/marketing/mid-banner-1.jpg'
export const PLP_BANNER_2 =
  'https://img.damensch.com/marketing/mid-banner-2.jpg'
export const PLP_BANNER_3 =
  'https://img.damensch.com/marketing/mid-banner-3.jpg'
export const PLP_BANNER_4 =
  'https://img.damensch.com/marketing/mid-banner-1.jpg'
export const PLP_BANNER_5 =
  'https://img.damensch.com/marketing/mid-banner-2.jpg'

// APP LINKS
export const PLP_APP_BANNER_CLICK_URL = 'https://damensch.app/plp-app-bnr'

// Referrals
export const REFERRAL_PREDEFINED_TEXT = `
Hey! Found an amazing deal for you.

Get 15% OFF on your first order from DaMENSCH! — https://www.damensch.com/

Use my below referral code to claim the offer:

`
export const REFERRAL_NOT_AVAILABLE_MESSAGE =
  'The referral reward is made available after 30 days of your referred friend placing the order, subject to that order not getting cancelled/refunded. This is done to prevent misuse of referral program codes.'
// export const REFERRAL_IS_EXPIRED_MESSAGE = 'The voucher code validity has expired because your referee order has not yet delivered.';
// export const REFERRAL_NOT_DELIVERED_MESSAGE = 'The voucher code is not applicable because your referee order has not yet delivered.';
// export const REFERRAL_EXISTING_CUSTOMER_MESSAGE = `Sorry, you're not eligible for the referral discount. You are an existing DaMENSCH customer.`;
// export const REFERRAL_ALREADY_APPLIED_CODE_MESSAGE = `This coupon is already applied`;
export const REFERRAL_IS_EXPIRED_MESSAGE = `DaMN! This voucher has expired as your friend's order is still in transit.`
export const REFERRAL_NOT_DELIVERED_MESSAGE = `Your voucher code is not applicable because your friend's order is still in transit.`
export const REFERRAL_EXISTING_CUSTOMER_MESSAGE = `Hey! We recognise you! You're already a customer. Unfortunately this offer is not applicabe to you.`
export const REFERRAL_ALREADY_APPLIED_CODE_MESSAGE = `You've used this code once already. Can't use it again!`
export const REFERRAL_PREDEFINED_TEXT1 = `
Hey Bro!
Get ready to be the most comfortable version of yourself, right now!

Here’s FLAT 20% off on your first order from DaMENSCH!
Fill up your cart and use my Bro Code: `
export const REFERRAL_PREDEFINED_TEXT2 = `
Visit DaMENSCH now: https://dmnsh.in/brocode
`

export const AppsFlyerParams = {
  AFEventParamContentType: 'af_content_type', //affiliation
  AFEventParamContent: 'af_content', //Product name
  AFEventParamSuccess: 'af_success',
  AFEventParamPrice: 'af_price',
  AFEventParamContentId: 'af_content_id', //stockCode
  AFEventParamContentList: 'af_content_list',
  AFEventParamCurrency: 'af_currency',
  AFEventParamQuantity: 'af_quantity',
  AFEventParamRegistrationMethod: 'af_registration_method',
  AFEventParamPaymentInfoAvailable: 'af_payment_info_available',
  AFEventParamSearchString: 'af_search_string',
  AFEventParamCustomerUserId: 'af_customer_user_id',
  AFEventParamRevenue: 'af_revenue',
  AFEventParamNewVersion: 'af_new_version',
  AFEventParamCouponCode: 'af_coupon_code',
  AFEventParamOrderId: 'af_order_id',
  AFEventParam1: 'af_param_1', //isLoggedIn
  AFEventParam2: 'af_param_2', //Mobile number
  AFEventParam3: 'af_param_3', //current_page
  AFEventParam4: 'af_param_4', //section_title
  AFEventParam5: 'af_param_5', //category1
  AFEventParam6: 'af_param_6', //category2
  AFEventParamCity: 'af_city',
  AFEventParamRegion: 'af_region',
  AFEventParamCountry: 'af_country',
  AFEventParamCustomInfo: 'custom_info',
  AFEventParamCustomPaymentInfo: 'custom_payment_info',
}

export const AppsFlyerKeys = {
  AddToCart: 'af_add_to_cart',
  AddToWishlist: 'af_add_to_wishlist',
  // ViewCart: BranchEvent.ViewCart,
  InitiatePurchase: 'af_initiated_checkout',
  AddPaymentInfo: 'af_add_payment_info',
  Purchase: 'af_purchase',
  ViewItem: 'af_content_view',
  ViewItems: 'af_list_view',
  Login: 'af_login',
  Search: 'af_search',
}

export enum FacebookEvents {
  ADD_TO_CART = 'AddToCart',
  ADD_TO_WISHLIST = 'AddToWishlist',
  VIEW_CONTENT = 'ViewContent',
  PURCHASE = 'Purchase',
  ADD_PAYMENT_INFO = 'AddPaymentInfo',
  INITIATE_CHECKOUT = 'InitiateCheckout',
  PAGE_VIEW = 'PageView',
}

export const ACTION_TYPES = {
  SORT_BY: 'SORT_BY',
  PAGE: 'PAGE',
  SORT_ORDER: 'SORT_ORDER',
  CLEAR: 'CLEAR',
  HANDLE_FILTERS_UI: 'HANDLE_FILTERS_UI',
  ADD_FILTERS: 'ADD_FILTERS',
  REMOVE_FILTERS: 'REMOVE_FILTERS',
  FREE_TEXT: 'FREE_TEXT',
  SET_FILTERS: 'SET_FILTERS',
}

export const EmptyString = ''

export const SESSION_LOGIN_PROMPT = 'loginPromptDisplayed'
export const WELCOME10_TEXT = 'WELCOME10'

export const COUPON_CODE = 'WELCOME10'
export const COUPON_CODE_WITHOUT_LOGIN = ['welcome10', 'welcomeback', 'welcome']
export const PROMO_ERROR_MESSAGE_WITHOUT_LOGIN = `Hold on! You’re not logged in yet. Log in & try again.`
export const PROMO_ERROR_MESSAGE_WITH_LOGIN =
  'Uh oh! This offer is only for 1st orders. Try out our other coupons!'

export const TrackOrderInfo = {
  EXPECTED_DELIVERY_DATE: 'edd',
  PAYMENT_DETAILS: 'payment_details',
  NEED_HELP: 'need_help',
}